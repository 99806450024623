@use 'sass:math';
@use 'src/variables' as *;
@use 'src/placeholders' as *;

.title {
  @extend %span-12;

  align-items: center;
  box-sizing: border-box;
  display: flex;
  padding: 0 0 $gap $gap;
  width: 100%;

  &:not(:first-of-type) {
    margin-top: $spacing;
    margin-bottom: 0;
  }
}

.icon {
  margin-right: math.div($spacing, 4);
}

.text {
  @extend %text-accent;

  display: inline-block;
  font-size: $font-size;
  font-weight: normal;
}
