@use 'sass:color';
@use 'sass:math';
@use 'src/variables' as *;
@use 'src/mixins' as *;

@mixin social-link-hover($hover-color: $primary-dark) {
  &:hover,
  &:focus {
    .icon {
      background: $hover-color;
    }

    .text {
      color: $hover-color;
    }
  }

  &:focus {
    @include default-focus($hover-color);
  }
}

.link {
  @include social-link-hover;

  align-items: center;
  background: $content-background;
  border-radius: math.div($spacing, 2);
  box-sizing: border-box;
  color: $primary;
  display: grid;
  grid-auto-flow: column;
  height: $button-height;
  justify-content: flex-start;
  overflow: hidden;
  text-decoration: none;
  text-transform: uppercase;
  width: 100%;

  .icon {
    align-items: center;
    background: currentColor;
    display: flex;
    height: 100%;
    justify-content: center;
    width: $button-height;

    svg {
      color: $content-background;
      height: $spacing-alt;
      width: $spacing-alt;
    }
  }

  .text {
    color: currentColor;
    margin-left: $spacing;
  }
}

$socials: ("discord", "twitter");

@each $social in $socials {
  .#{$social} {
    @include social-link-hover(social-color($social, "dark"));

    color: social-color($social);
  }
}
