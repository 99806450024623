@use 'sass:math';
@use 'src/variables' as *;

.indicator {
  $size: math.div($spacing, 2);

  border-radius: math.div($size, 2);
  display: inline-block;
  flex-shrink: 0;
  height: $size;
  width: $size;

  &.has-text {
    margin-right: $size;
  }

  &.online {
    background: $online;
  }

  &.offline {
    background: $offline;
  }
}
