@use 'src/mixins' as *;
@use 'src/placeholders' as *;

.copy {
  @include button-like;

  justify-content: flex-start;
  overflow: auto;
  scrollbar-width: thin;
}

.vote {
  @extend %span-2;

  @include lg-only {
    grid-column-end: span 1;
  }

  @include xl-up {
    justify-self: center;
    max-width: 50%;
  }
}
