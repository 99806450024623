/// Colors

@use 'sass:color';
@use 'sass:math';

$primary: #f58300;
$primary-dark: color.adjust($primary, $hue: -10, $lightness: -2.5%);
$primary-darker: color.adjust($primary-dark, $hue: -5, $lightness: -2.5%);

$sponsored: #e3ab02; // also used for form errors or warnings, keep that in mind if you change it
$sponsored-background: color.adjust($sponsored, $lightness: -5%, $alpha: -0.75);

$site-background: #282e36;
$content-background: #13151e;
$content-hover: color.adjust($site-background, $lightness: 10%);
$content-transparent: color.change($content-background, $alpha: 0.25);
$overlay-background: color.change(#000, $alpha: 0.25);

$text-color: #b8c7c9;
$text-muted: #6d797a; // also for normal icons i.e. not links or buttons
$text-accent: #fff;
$text-dark: #000; // text and icons on buttons

$online: #4faf3e;
$offline: #fa0328;
$offline-dark: color.adjust($offline, $hue: 10, $lightness: -10%);

$invalid: color.adjust($offline, $hue: 5, $saturation: -10%, $lightness: 5%);
$invalid-background: color.change($invalid, $alpha: 0.15);

$social-colors: (
  "discord": (
    "base": #7289da,
    "dark": #5064ec,
  ),
  "twitter": (
    "base": #1da1f2,
    "dark": #167afb,
  ),
  "instagram": (
    "base": #c13584,
  ),
);

@function social-color($social, $shade: "base") {
  @return map-get(map-get($social-colors, $social), $shade);
}

/// Units

$font-size: 16px;
$font-small: 14px;

$icon-size: 20px;

$gap: $font-size * 0.5;
$spacing: $font-size; // main content
$halfSpacing: math.div($spacing, 2);
$doubleSpacing: $spacing * 2;
$spacing-alt: $font-size * 1.5; // sidebar

$sidebar-width: 172px;
$spacing-content: 3.5vw; // spacing around main content on landscape tablets & up

$button-height: 42px;

/// Strings

$font-family: "Source Sans Pro", helvetica, "Lucida Grande", arial, sans-serif;
