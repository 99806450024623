@use 'src/variables' as *;
@use 'src/mixins' as *;
@use 'src/placeholders' as *;

.container {
  @extend %container;

  align-items: center;
  font-size: $font-small;
  grid-template-columns: repeat(2, minmax(0, max-content));
  grid-gap: 0 $spacing;
  padding: $spacing;

  &.full {
    grid-template-columns: min-content;
  }

  hr {
    @extend %span-2;

    border: 0;
    height: $gap;
    margin: 0;
  }

  & + & {
    margin-top: $spacing;
  }

  @include lg-only {
    grid-template-columns: minmax(0, max-content);

    hr {
      grid-column-end: span 1;
    }

    &.full {
      grid-template-columns: auto;
    }
  }
}

.aside {
  @extend %span-12;

  #status {
    justify-content: space-between;
  }

  .vote {
    @extend %span-2;

    @include lg-only {
      grid-column-end: span 1;
    }
  }

  @include lg-up {
    grid-column-end: span 4;
  }

  @include xl-up {
    .vote {
      justify-self: center;
      max-width: 50%;
    }
  }
}

.main {
  @extend %span-12;

  .container {
    grid-template-columns: 1fr;
  }

  @include lg-up {
    grid-column-end: span 8;
  }
}

.links {
  @extend %content;

  grid-template-columns: repeat(2, minmax(0, 50%));
  margin-top: $gap;

  @include md-up {
    grid-template-columns: repeat(3, minmax(0, 33.3333%));
  }

  @include xl-up {
    grid-template-columns: repeat(4, minmax(0, 25%));
  }
}

.charts {
  @extend %span-12;

  .container {
    position: relative;

    @include orientation-portrait {
      height: 30vh;
    }
    @include orientation-landscape {
      height: 60vh;
    }
  }
}
