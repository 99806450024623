@use 'src/variables' as *;
@use 'src/mixins' as *;

.wrapper,
.group {
  grid-gap: $gap;
}

.wrapper,
.submitted {
  @extend %container;

  padding: $spacing;
}

.submitted {
  grid-gap: $spacing;
  justify-items: center;
  text-align: center;
}

.icon {
  color: $online;
  height: 5em;
  width: 5em;
}

.group {
  display: grid;
  margin-top: $spacing;
}

.groupedButtons {
  display: inline-block;
  padding: 20px 0;
  width: 100%;
}

.btnGroup {
  width: 40%;
  margin: 0 5%;
  display: inline-block;
}

.mfa {
  font-family: monospace, $font-family;
}

@include md-up {
  .wrapper,
  .submitted {
    margin: auto;
    width: 500px;
  }
}
