@use 'src/variables' as *;
@use 'src/mixins' as *;

// spinning animation for loading stuff
@keyframes spin {
  from {
    transform: rotate(0deg) scale(-1, 1);
  }
  to {
    transform: rotate(360deg) scale(-1, 1);
  }
}

.btn {
  @include button-like;
}

.sponsored {
  @include button-like($sponsored);
}

.btn,
%hollow-base {
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;

    &:active {
      transform: none;
    }
  }
}

// Colors
%hollow-base {
  background: inherit;
  border: 1px solid currentColor;

  &:not(:disabled):hover {
    border: 0;
    color: $text-dark;
  }

  &:not(:disabled):focus {
    background: inherit;
    border: inherit;
    box-shadow: 0 0 2px 2px $primary-dark;
    color: $primary-dark;
  }
}

@mixin hollow($color: $primary, $text: "black") {
  @extend %hollow-base;

  background-color: $color;
  color: $text;
}

.hollow {
  @include hollow;
}

.delete {
  @include hollow($offline);
}

.icon {
  padding: 0;
  width: $button-height;
}

// Size
.small {
  @include lg-up {
    width: 50%;
  }

  @include xl-up {
    width: 25%;
  }
}

.spin {
  animation: spin 2s infinite linear;
}
