@use 'sass:math';
@use 'src/variables' as *;
@use 'src/mixins' as *;

.server {
  align-items: center;
  background: $content-background;
  border-radius: $gap;
  box-sizing: border-box;
  display: grid;
  grid-gap: $gap;
  grid-template:
    "banner banner"
    "badges recap"
    auto/minmax(0, 20%) minmax(0, 80%);
  padding: 0 0 $spacing 0;
  width: 100%;

  @include md-up {
    border-radius: $spacing;
    grid-template:
      "badges banner"
      "badges recap" auto / 12.5% auto;
    padding: $spacing;
  }

  @include xl-up {
    grid-gap: math.div($spacing, 4);
    grid-template: "badges recap banner" auto / 8% minmax(0, 42%) 468px;
  }
}

.banner {
  border-radius: $gap;
  grid-area: banner;
  margin: auto;

  &:focus {
    box-shadow: 0 0 1px 2px $primary-dark;
    outline: 0;
  }

  @include md-up {
    margin: 0 auto 0 0;
  }
}

.badges {
  box-sizing: border-box;
  display: grid;
  grid-area: badges;
  grid-gap: $spacing;
  grid-template-rows: 1fr 1fr;
  text-align: center;

  .rank {
    color: $sponsored;
    font-size: 1.25em;
    font-weight: bolder;
    margin: auto;
  }

  .flag {
    margin: auto;
    max-width: 1.5em;
  }

  @include md-up {
    .rank {
      font-size: 1.5em;
    }

    .flag {
      max-width: 1.75em;
    }
  }

  @include xl-up {
    .flag {
      max-width: 2.5em;
    }
  }
}

.recap {
  grid-area: recap;
  margin-right: $gap;

  .tidbit {
    @include tag-like {
      align-items: center;
      background: $site-background;
      display: flex;
      font-size: $font-size;
      overflow: auto;
      padding: 0 $gap;
      text-align: center;
      height: 2em;

      &:focus,
      &:focus-within {
        @include default-focus;
      }
    }
  }

  .name {
    font-size: 1.25em;
    font-weight: normal;

    &-link {
      @extend %text-accent;

      display: inline-block;
      text-decoration: none;

      &:focus {
        @include default-focus;

        border-radius: $gap;
      }
    }
  }

  @include md-up {
    margin: 0;

    .name {
      font-size: 1.5em;
    }
  }
}

.is-sponsored {
  background: $sponsored-background;
  border: 1px solid $sponsored;

  .star {
    width: $font-size * 2;
  }
}
