@use 'src/variables' as *;
@use 'src/mixins' as *;

.actions {
  display: grid;
  grid-auto-flow: column;
  grid-column-end: span 12;
  grid-gap: 16px;
  justify-content: center;
}
