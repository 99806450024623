$font-dir: "./assets/fonts";

@font-face {
  font-family: "Source Sans Pro";
  src: url("#{$font-dir}/SourceSansPro/SourceSansPro-Regular.ttf")
    format("truetype");
}

@font-face {
  font-family: "Source Sans Pro";
  font-weight: bold;
  src: url("#{$font-dir}/SourceSansPro/SourceSansPro-Bold.ttf")
    format("truetype");
}

@font-face {
  font-family: "Source Sans Pro";
  font-weight: 900;
  src: url("#{$font-dir}/SourceSansPro/SourceSansPro-Black.ttf")
    format("truetype");
}
