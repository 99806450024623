@keyframes menu-top-slice-in {
  0% {
    transform: translateY(-9px);
  }
  33%,
  66% {
    transform: translateY(0);
  }
  100% {
    transform: rotate(45deg);
  }
}

@keyframes menu-top-slice-out {
  0% {
    transform: rotate(45deg);
  }
  33%,
  66% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-9px);
  }
}

@keyframes menu-inner-slice-in {
  0%,
  66% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(-45deg);
  }
}

@keyframes menu-inner-slice-out {
  0% {
    transform: rotate(-45deg);
  }
  66%,
  100% {
    transform: rotate(0);
  }
}

@keyframes menu-bottom-slice-in {
  0% {
    transform: translateY(9px);
  }
  33%,
  66% {
    transform: translateY(0);
  }
  100% {
    transform: rotate(45deg);
  }
}

@keyframes menu-bottom-slice-out {
  0% {
    transform: rotate(45deg);
  }
  33%,
  66% {
    transform: rotate(0);
  }
  100% {
    transform: translateY(9px);
  }
}
