@use 'src/variables' as *;
@use 'src/mixins' as *;
@use 'src/placeholders' as *;

.form {
  display: grid;
  grid-gap: $spacing;

  .search {
    grid-column-end: span 2;
  }

  @include md-up {
    grid-template-columns: repeat(4, 1fr);
  }
}

.list {
  @extend %reset-list;
  @extend %content;
}
