@use 'sass:math';
@use 'src/variables' as *;
@use 'src/mixins' as *;
@use 'src/placeholders' as *;

.section {
  display: flex;
  flex-flow: wrap;
  grid-column-end: span 12;
}

.container {
  @extend %container;

  font-size: $font-small;
  flex: 1 100%;
  grid-gap: $spacing;
  padding: $spacing;

  & + & {
    margin-top: $spacing;
  }

  @include md-up {
    font-size: $font-size;
    flex: 1;

    & + & {
      margin: 0 0 0 $spacing;
    }
  }
}

%input-group {
  align-items: center;
  display: grid;
  grid-gap: $gap $spacing;
}

.data {
  @extend .section;

  .container {
    margin-left: 0;
  }

  @include md-up {
    flex-flow: column;
    grid-column-end: span 6;

    .container + .container {
      margin-top: $spacing; // in media query due to specificity
    }
  }
}

.info {
  @extend .container;

  .input-group {
    @extend %input-group;

    @include md-up {
      grid-template-columns: repeat(12, 1fr);

      .label {
        grid-column: 1 / span 2;
      }

      .button,
      .name,
      .email {
        grid-column-start: 3;
      }

      .name {
        grid-column-end: span 4;
      }

      .email {
        grid-column-end: span 8;
      }

      .button {
        grid-column-end: span 6;
      }
    }

    @include xl-up {
      .name {
        grid-column-end: span 3;
      }

      .email {
        grid-column-end: span 5;
      }

      .button {
        grid-column-end: span 4;
      }
    }
  }
}

%text-inactive {
  color: $offline;
}

.security {
  @extend .container;

  align-content: space-between;

  &#two-factor {
    text-align: center;
  }

  .input-group {
    @extend %input-group;

    @include xl-up {
      grid-template-columns: 1fr 2fr;

      .button {
        grid-column-start: 2;
      }
    }
  }

  .active {
    color: $online;
  }

  .inactive {
    @extend %text-inactive;
  }

  .active,
  .inactive {
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .icon {
    color: inherit;
    height: $font-size * 5;
    width: $font-size * 5;
  }

  .text {
    font-size: $font-size;
  }
}

@include xl-up {
  .center-group {
    display: grid;
    grid-gap: math.div($spacing, 2);
    grid-template-columns: 1fr 4fr 1fr;
  }

  .button {
    grid-column-start: 2;
  }
}
