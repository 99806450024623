@use 'sass:math';
@use 'src/variables' as *;
@use 'src/mixins' as *;

.banner {
  border-radius: math.div($spacing, 2);
  box-sizing: border-box;
  display: block;
  height: auto;
  max-width: 100%;
  width: 468px;
}
