@use 'variables' as *;
@use 'sass:math';

%span-2 {
  grid-column-end: span 2;
}

%span-12 {
  grid-column-end: span 12;
}

%content {
  display: grid;
  grid-gap: $spacing;
}

%container {
  @extend %span-12;

  background: $content-background;
  border-radius: $spacing;
  display: grid;
}

%reset-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

%text-accent {
  color: $text-accent;
}

%link {
  cursor: pointer;
  text-decoration: none;

  svg {
    color: inherit;
  }

  &:focus,
  &:hover {
    @extend %text-accent;

    text-decoration: underline;
  }
}

%button {
  align-items: center;
  border: 0;
  border-radius: math.div($spacing, 2);
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  font: normal $font-size $font-family;
  height: $button-height;
  text-decoration: none;
  text-transform: uppercase;
  transform-style: preserve-3d;
  transition: transform 50ms ease-in-out;
  justify-content: center;
  padding: 0 0.5em;
  width: 100%;

  &:focus,
  &:focus-within {
    outline: 0;
  }

  &:active {
    transform: translateY(2px);
  }

  svg {
    color: inherit;
  }
}
