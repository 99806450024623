@use 'sass:math';
@use 'src/placeholders' as *;
@use 'src/variables' as *;
@use 'src/mixins' as *;

.footer {
  background: $content-background;
  box-sizing: border-box;
  display: grid;
  grid-gap: 0 $spacing;
  padding: 1em $spacing-alt;
  width: 100%;
  margin-top: 2vh;

  @include lg-up {
    grid-template-columns: repeat(12, 1fr);
    padding: $doubleSpacing $spacing-content $spacing;
    padding-left: calc(#{$sidebar-width} + #{$spacing-content});
  }
}

.main {
  text-align: center;

  @include lg-up {
    grid-column-end: span 8;
    text-align: left;
  }
}

.social {
  display: block;
}

%icon {
  color: $text-muted;
  display: inline-block;
  width: $button-height;

  &:not(:last-of-type) {
    margin-right: math.div($button-height, 2);
  }

  &:focus {
    outline: none;
  }
}

$socials: ("discord", "twitter", "instagram");

@each $social in $socials {
  ##{$social} {
    @extend %icon;

    &:hover,
    &:focus {
      color: social-color($social);
    }
  }
}

.copy {
  font-size: $font-small;
}

.label {
  @extend %text-accent;

  font-size: 1em;
  font-weight: normal;
  margin-bottom: 0.5em;
}

.links {
  display: block;
  text-align: center;

  .item {
    display: inline;
    font-size: $font-small;

    &:not(:first-child)::before {
      content: "/";
      margin: 0 0.5em;
    }
  }

  @include lg-up {
    grid-column-end: span 2;
    text-align: left;

    .item {
      display: block;

      &:not(:first-child)::before {
        content: none;
        margin: 0;
      }
    }
  }
}
