@use 'sass:math';
@use 'src/placeholders' as *;
@use 'src/variables' as *;
@use 'src/mixins' as *;

.tabs {
  display: flex;
  grid-column-end: span 12;
  position: relative;

  &::after {
    background: $content-background;
    bottom: 0;
    content: "";
    height: 2px;
    position: absolute;
    width: 100%;
    z-index: 1;
  }
}

.tab {
  border-bottom: 2px solid $content-background;
  color: $text-color;
  flex: 1;
  padding: math.div($spacing-alt, 2) $spacing-alt;
  text-align: center;
  text-decoration: none;
  z-index: 2;

  &.active {
    border-color: $primary;
    color: $primary;
  }

  &:hover,
  &:focus {
    color: $text-accent;
  }

  &:focus {
    @include default-focus;
  }

  @include md-up {
    flex: 0;
  }
}
