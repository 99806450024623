@use 'sass:color';
@use 'variables' as *;
@use 'placeholders' as *;

/// Media queries
@mixin orientation-portrait {
  @media only screen and (orientation: portrait) {
    @content;
  }
}

@mixin orientation-landscape {
  @media only screen and (orientation: landscape) {
    @content;
  }
}

// larger smartphones
@mixin sm-up {
  @media only screen and (min-width: 480px) {
    @content;
  }
}

// landscape smartphones, portrait tablets
@mixin md-up {
  @media only screen and (min-width: 720px) {
    @content;
  }
}

// landscape tablets, small desktops - sidebar breakpoint
@mixin lg-up {
  @media only screen and (min-width: 920px) {
    @content;
  }
}

@mixin lg-only {
  @include lg-up {
    @media only screen and (max-width: 1199px) {
      @content;
    }
  }
}

// desktops
@mixin xl-up {
  @media only screen and (min-width: 1200px) {
    @content;
  }
}

/// Styles

@mixin button-like(
  $background: $primary,
  $color: $text-dark,
  $hover-background: $primary-dark,
  $focus-border: $content-background,
  $active-background: $primary-darker
) {
  @extend %button;

  background: $background;
  color: $color;

  &:not(:disabled):hover,
  &:not(:disabled):focus,
  &:not(:disabled):focus-within {
    background: $hover-background;
  }

  &:not(:disabled):focus,
  &:not(:disabled):focus-within {
    border: 2px solid $focus-border;
    box-shadow: 0 0 2px 2px $hover-background;
  }

  &:not(:disabled):active {
    background: $active-background;
  }
}

@mixin tag-like {
  margin: $gap $gap 0 0;
  max-width: 100%;

  .content {
    @content;

    border-radius: $gap;
  }
}

@mixin default-focus($color: $primary-dark) {
  box-shadow: 0 0 1px 1px $color;
  outline: 0;
}

@mixin content($col: 12) {
  @extend %content;

  grid-template-columns: repeat($col, 1fr);
}

@mixin notice {
  align-items: center;
  border-radius: $gap;
  box-sizing: border-box;
  display: grid;
  grid-column-end: span 12;
  grid-gap: $spacing;
  justify-items: center;
  margin: auto;
  padding: $spacing;
  text-align: center;
  width: 100%;

  .button {
    width: 100%;
  }

  @include md-up {
    width: 80%;

    .button {
      width: 70%;
    }
  }

  @include xl-up {
    width: 50%;
  }
}
