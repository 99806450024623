@use 'src/variables' as *;
@use 'src/placeholders' as *;

.copyable {
  @extend %text-accent;

  max-width: 100%;
}

.input {
  background: none;
  border: 0;
  color: inherit;
  cursor: pointer;
  flex-shrink: 0;
  font-family: $font-family;
  font-size: $font-size;
  text-align: inherit;
  text-decoration: underline;

  &:focus {
    outline: 0;
  }
}
