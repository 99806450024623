@use 'sass:math';
@use 'src/placeholders' as *;
@use 'src/variables' as *;
@use 'src/mixins' as *;

%wrapper {
  @extend %reset-list;

  background: $site-background;
  border-radius: $gap;
  box-sizing: border-box;
  display: flex;
  min-height: $button-height;
  position: relative;
  width: 100%;

  &.dark {
    background: $content-background;
    border-color: $content-background;
  }

  &.disabled {
    background: $text-muted;

    .input {
      color: $content-background;
    }
  }

  .icon,
  .extra {
    color: $text-muted;
    margin-left: $gap;
  }

  &:focus-within {
    @include default-focus;

    .icon {
      color: $primary-dark;
    }

    .extra {
      color: $text-color;
    }
  }
}

%input {
  @extend %text-accent;

  background: none;
  border: 0;
  border-radius: $gap;
  box-sizing: border-box;
  font: normal $font-size $font-family;
  flex: 1;
  min-height: $button-height;
  outline: 0;
  padding: $gap;
}

%monoinput {
  @extend %input;

  font-family: monospace, $font-family;
}

.input {
  @extend %input;

  width: 100%;
}

.monoinput {
  @extend %monoinput;

  width: 100%;
}

.extra + .input {
  padding-left: 2px;
}

// Generic text input wrapper
.wrapper {
  @extend %wrapper;

  align-items: center;
}

.invalid {
  .wrapper:not(:focus-within),
  .tags:not(:focus-within) {
    background: $sponsored-background;
    box-shadow: 0 0 2px 1px $sponsored;

    > .icon {
      color: $sponsored;
    }
  }

  .wrapper.disabled {
    box-shadow: 0 0 1px 1px $sponsored;
    opacity: 0.75;

    .input {
      color: $text-accent;
      opacity: 0.5;
    }
  }
}

input {
  &:invalid {
    border: 0;
    box-shadow: none;
  }

  &:checked {
    + .box,
    + .radio {
      background: $primary;

      &::before,
      &::after {
        background: $content-background;
        border-radius: 2px;
        content: "";
        display: inline-block;
        height: 3px;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        position: absolute;
        width: math.div($spacing-alt, 2);
      }

      &::before {
        transform: rotate(45deg);
      }

      &::after {
        transform: rotate(-45deg);
      }
    }
  }
}

.check {
  align-items: center;
  border-radius: $gap;
  cursor: pointer;
  display: flex;
  padding: $gap 0;

  input {
    cursor: pointer;
    opacity: 0;
    position: absolute;
  }

  .box,
  .radio {
    border: 2px solid $primary;
    display: inline-block;
    margin-right: $gap;
    position: relative;
    height: $spacing-alt;
    width: $spacing-alt;
  }

  .box {
    border-radius: $gap;
  }

  .radio {
    border-radius: 50%;
  }

  &:hover,
  &:focus {
    color: $text-accent;
  }

  &:focus-within {
    @include default-focus;
  }
}

.helpers {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.errors {
  color: $sponsored;
  display: flex;
  font-size: $font-small;
  line-height: 1.5;

  .icon {
    color: inherit;
    height: $font-small;
    margin: math.div($font-small, 4) math.div($gap, 2) 0 0;
    width: $font-small;
  }
}

.wrapper + .errors,
.upload + .errors,
.tags + .errors,
.helpers {
  margin-top: math.div($gap, 2);
}

.upload {
  align-items: center;
  border: 4px dashed $site-background;
  border-radius: $gap;
  cursor: pointer;
  display: flex;
  flex-flow: column;
  padding-bottom: $spacing;
  position: relative;

  &:focus {
    outline: 0;
  }

  &:not(.has-file) {
    &.dragging {
      background: $overlay-background;
    }

    &:focus {
      border-color: $primary-dark;
    }
  }

  &.has-file {
    cursor: auto;
  }

  .icon {
    height: $icon-size * 4;
    width: $icon-size * 4;
  }

  .tip {
    font-size: $font-size;
  }

  .actions {
    display: grid;
    grid-auto-flow: column;
    grid-gap: $spacing;
    margin-top: $spacing;
  }

  @include md-up {
    padding: $spacing;

    .icon {
      height: $icon-size * 6;
      width: $icon-size * 6;
    }
  }
}

.invalid .upload {
  box-shadow: 0 0 4px $sponsored;

  &,
  &:focus {
    border-color: transparent;
  }
}

.tags {
  @extend %wrapper;

  margin-right: $gap;
}

.item {
  @extend %reset-list;

  align-items: center;
  display: flex;
  flex: 1;
  width: 100%;
}

.item,
.tag-input {
  padding-right: 0;
}

.tag-input {
  @extend %input;

  flex: 1;
  max-width: 100%;
  width: 16ch;
}

.suggestions {
  @extend %reset-list;

  background: $site-background;
  border: 1px solid $content-background;
  border-radius: $gap;
  box-shadow: 2px 2px 8px 0 $content-background;
  font-size: $font-size;
  max-height: $spacing * 2.5 * 4;
  left: 0;
  overflow-y: auto;
  position: absolute;
  right: 0;
  top: calc(100% + 4px); // compensate input border
  z-index: 3;
}

.suggestions:not(.is-focused) {
  display: none;
}

.suggestion {
  align-items: center;
  background: none;
  border: 0;
  color: $text-color;
  display: flex;
  font: normal $font-size $font-family;
  height: $spacing * 2.5;
  padding: 0 $spacing;
  text-align: left;
  width: 100%;

  &:not(:disabled) {
    cursor: pointer;
  }

  &.is-active {
    background: $content-hover;
    color: $text-accent;
    outline: 0;
  }

  &:disabled {
    color: $text-muted;
    font-style: italic;
  }

  .extra {
    margin: 0 $gap 0 0;
  }
}

.switch {
  border-radius: $spacing-alt;
  height: $spacing-alt;
  position: relative;
  width: $spacing-alt * 2;

  &:focus-within {
    box-shadow: 0 0 2px 2px $primary-darker;
    outline: 0;
  }

  input {
    cursor: pointer;
    height: 100%;
    margin: 0;
    opacity: 0;
    position: absolute;
    width: 100%;
  }

  .slider {
    align-items: center;
    background: $text-muted;
    border: 0;
    border-radius: $spacing-alt;
    box-sizing: border-box;
    display: flex;
    height: 100%;
    padding: math.div($gap, 2);
    pointer-events: none; // don't let it hijack toggle event
    width: 100%;

    &::before {
      background: $content-background;
      border-radius: 50%;
      content: "";
      height: $spacing;
      transition: transform 150ms ease-in;
      width: $spacing;
    }
  }

  input:checked + .slider {
    background: $primary;

    &::before {
      transform: translateX($spacing-alt);
    }
  }
}
