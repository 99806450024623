@use 'variables' as *;
@use 'mixins' as *;
@use 'type' as *;
@use 'placeholders' as *;

html {
  height: 100%;
  accent-color: $primary;
}

body {
  background: $site-background;
  box-sizing: border-box;
  color: $text-color;
  font: normal $font-size $font-family;
  height: 100%;
  line-height: 1.5;
  margin: 0;
  padding: 0;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

:global {
  #App {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  h1,
  h2,
  h3 {
    @extend %text-accent;

    font-family: $font-family;
    font-weight: normal;
    line-height: 1;
  }

  h1 {
    font-size: 1.5em;
  }

  h2 {
    font-size: 1.25em;
  }

  h3 {
    font-size: 1.15em;
  }

  h1,
  h2,
  h3,
  p {
    margin: 0;
  }

  a {
    > svg + span,
    > span + svg {
      margin-left: $gap * 0.5;
    }
  }

  .content {
    @include content(1);

    &-columns {
      @include content(12);
    }
  }

  .list-unstyled {
    @extend %reset-list;
  }

  .text-accent {
    @extend %text-accent;
  }

  .text-emphasis {
    @extend %text-accent;

    font-weight: 700;
  }

  .text-muted,
  .text-helper {
    color: $text-muted;
  }

  .text-helper {
    font-size: $font-small;
  }

  .link {
    @extend %link;

    color: $primary;
  }

  .link-muted {
    @extend %link;

    color: $text-color;
  }

  .link-delete {
    @extend %link;

    color: $offline;
  }

  .list {
    list-style: inside;
    margin: 0;
    padding-left: $spacing;
  }

  .action {
    align-items: center;
    display: flex;
    outline: 0;
  }

  .notice {
    @include notice;

    background: $content-transparent;
    border: 1px solid $content-background;
  }

  .notice-sponsored {
    @include notice;

    background: $sponsored-background;
    border: 1px solid $sponsored;
    color: $sponsored;

    > p {
      display: flex;
      align-items: center;

      svg {
        color: $sponsored;
      }
    }
  }
}

.main {
  flex: 1 0 auto;
  margin: $spacing-alt * 3 0 0 0; // compensate menu height
  padding: $spacing $spacing 0 $spacing;

  @include lg-up {
    margin: 0;
    padding: $spacing-alt $spacing-content 0
      calc(#{$sidebar-width} + #{$spacing-content});
  }
}
