@use 'sass:math';
@use 'src/variables' as *;
@use 'src/mixins' as *;
@use 'src/placeholders' as *;
@use './animations' as *;

.menu {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: $spacing-alt * 3;
  left: 0;
  max-height: 100%;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 5;

  @include lg-up {
    background: $content-background;
    border-radius: 0 1em 1em 0;
    border-right: 1px solid $site-background;
    height: 100%;
    width: $sidebar-width;
  }
}

.navbar {
  background: $content-background;
  border-bottom: 1px solid $site-background;
  border-radius: 0 0 1em 1em;
  display: flex;
  justify-content: space-between;
  width: 100%;
  z-index: 5;

  &-item {
    border-radius: 1em;
    height: 1.5em;
    margin: 0.5em;
    padding: 1em;

    .logo {
      height: 1.5em;
    }

    .logo-text {
      height: 1.5em;
      margin-left: 0.5em;
    }

    &:focus,
    &:active {
      background: $site-background;
      outline: none;
    }
  }

  &.is-open {
    border-radius: 0;
  }

  @include lg-up {
    background: none;
    border: 0;

    &-item {
      height: unset;
      margin: math.div($spacing-alt, 2);
      padding: math.div($spacing-alt, 2) 0;
      width: 100%;
    }

    .logo {
      display: block;
      height: 2em;
      margin: 0 auto;
    }

    .logo-text {
      display: block;
      height: 1.25em;
      margin: $halfSpacing auto 0 auto;
    }

    .menu-btn {
      display: none;
    }
  }
}

.menu-btn {
  align-items: flex-end;
  background: none;
  border: 0;
  box-sizing: content-box;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1.5em;
  position: relative;
  width: $spacing-alt;

  .slice,
  &::before,
  &::after {
    animation: 0s forwards ease-out;
    background: $text-muted;
    border-radius: 1.5px;
    content: "";
    height: 4px;
    position: absolute;
    width: $spacing-alt;
  }

  &::before {
    animation-name: menu-top-slice-out;
  }

  .slice {
    animation-name: menu-inner-slice-out;
  }

  &::after {
    animation-name: menu-bottom-slice-out;
    transform-origin: 18.75% center;
    width: $spacing-alt * 0.625;
  }

  &.active {
    .slice,
    &::before,
    &::after {
      animation-duration: 300ms;
    }
  }
}

.is-open .menu-btn {
  background: $site-background;

  .slice,
  &::before,
  &::after {
    animation-duration: 0s;
  }

  &::before {
    animation-name: menu-top-slice-in;
  }

  .slice {
    animation-name: menu-inner-slice-in;
  }

  &::after {
    animation-name: menu-bottom-slice-in;
  }
}

@mixin navlinks-visible {
  overflow: visible;
  transform: translateY(0);
}

.navlinks {
  background: $content-background;
  bottom: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  font-size: $font-size;
  justify-content: space-between;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: fixed;
  top: $spacing-alt * 3;
  transform: translateY(calc(-100% - 5 * #{$font-size}));
  transition: transform 400ms ease-out;
  width: 100%;

  @include lg-up {
    @include navlinks-visible();

    background: none;
    font-size: $font-small;
    height: 100%;
    position: static;
    transition: none;
  }
}

.is-open .navlinks {
  @include navlinks-visible();
}

.buttons {
  @extend %reset-list;

  display: grid;
  grid-gap: $spacing;
  padding: $spacing-alt;
}

.navitem {
  margin: 0;
  padding: 0;
}

.navlink {
  @extend %text-accent;

  border-bottom: 1px solid $site-background;
  display: flex;
  padding: $spacing $spacing-alt;
  text-decoration: none;

  &.active {
    background: $site-background;
  }

  &:hover {
    background: $content-hover;
  }

  &:focus {
    box-shadow: 0 0 2px 1px $primary-dark;
    outline: 0;
  }

  &:active {
    background: $content-background;
  }

  .link-text {
    margin-left: 1em;
  }

  @include lg-up {
    border: 0;

    .link-text {
      margin-left: 0.75em;
    }
  }
}
