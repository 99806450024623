@use 'src/variables' as *;
@use 'src/mixins' as *;
@use 'src/placeholders' as *;

.header {
  @extend %container;

  min-height: 4.5em;
}

.title {
  align-items: center;
  display: flex;
  line-height: 1;
  margin: $halfSpacing 0 $halfSpacing $spacing-alt;
}

.flag {
  display: inline-block;
  margin-right: 0.5em;
  max-width: 1.5em;
}

.banner {
  align-items: center; // prevent stretching on mobile
  display: flex;
  height: auto;
  margin: 0 auto;
  max-width: 100%;
}

@include md-up {
  .header {
    grid-auto-flow: column;
  }

  .flag {
    margin-right: 1em;
    max-width: 2em;
  }

  .banner {
    margin: $gap $gap $gap auto;
  }
}
