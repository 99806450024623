@use 'sass:math';
@use 'src/variables' as *;
@use 'src/mixins' as *;
@use 'src/placeholders' as *;

/*
 * TODO:
 * - give inputs different widths based on expected content
 * - improve grouping at review stage
 */

.progress,
.step,
.step-indicator,
.section,
.buttons,
.group,
.title {
  display: flex;
}

.progress,
.section,
.buttons,
.group,
.title {
  grid-column-end: span 12;
}

.progress li,
.step-indicator,
.step {
  align-items: center;
  flex-flow: column;
  justify-content: center;
}

.progress li,
.step,
.group {
  width: 100%;
}

.step {
  border-radius: $gap;
  padding: $gap 0;

  &:focus {
    @include default-focus;

    color: $text-accent;
    outline: 0;
  }
}

.progress {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    $icon-width: $spacing * 0.75;

    cursor: pointer;
    position: relative;

    .label {
      margin-bottom: $gap;
    }

    .icon {
      color: $text-dark;
      height: $icon-width;
      width: $icon-width;
    }

    .step-indicator,
    .step-indicator::before,
    .step-indicator::after {
      background: $text-muted;
    }

    .step-indicator {
      border-radius: 50%;
      box-shadow: 0 0 0 4px $site-background;
      box-sizing: border-box;
      height: $spacing;
      width: $spacing;

      &::before,
      &::after {
        bottom: math.div($spacing - 4px, 2);
        content: "";
        height: 4px;
        position: absolute;
        width: 50%;
        z-index: -1;
      }

      &::before {
        left: 0;
      }

      &::after {
        right: 0;
      }
    }

    &:first-of-type .step-indicator::before,
    &:last-of-type .step-indicator::after {
      opacity: 0;
    }

    &.done,
    &.active {
      .step-indicator,
      .step-indicator::before {
        background: $primary;
      }
    }

    &.done .step-indicator::after {
      background: $primary;
    }

    &.active,
    &:hover {
      color: $text-accent;
    }

    &:hover .step-indicator {
      background: $text-accent;
    }
  }
}

.section,
.group {
  flex-flow: column;
}

.buttons,
.title {
  justify-content: space-between;
}

.title {
  margin: 0 $gap $gap;
}

.group .title {
  margin-top: $spacing;
}

.container,
.container-simple {
  @extend %container;

  font-size: $font-small;
  flex: 1;
  grid-gap: $gap $spacing;
  padding: $spacing;

  .label {
    display: flex;
  }
}

.container-simple {
  grid-gap: $gap;
}

.links {
  display: grid;
  grid-gap: $gap;
}

.link-label {
  width: 25%;
}

.button {
  &:only-child {
    margin-left: auto;
  }

  & + & {
    margin-left: $spacing;
  }
}

@include md-up {
  .button {
    max-width: 50%;
  }

  .container,
  .container-simple {
    font-size: $font-size;
  }

  .container,
  .links {
    grid-gap: $spacing;
  }

  .links {
    grid-template-columns: minmax(0, min-content) auto;
  }

  .container {
    grid-template-columns: minmax(0, 25%) minmax(0, 75%);

    .label,
    .link-label {
      margin-top: $gap;
    }
  }
}

@include lg-up {
  .progress {
    grid-column-end: span 8;
    grid-column-start: 3;
  }

  .section .section {
    flex-flow: row;
  }

  .group + .group {
    margin-left: $spacing;
  }

  .button {
    max-width: 33.3333%;
  }
}

@include xl-up {
  .button {
    max-width: 25%;
  }
}
