@use 'src/variables' as *;
@use 'src/mixins' as *;

.tag,
.editable {
  @include tag-like {
    background: $primary;
    border: 2px solid transparent;
    cursor: pointer;
    color: $text-dark;
    font-size: $font-small;
    padding: 0 $gap;
    text-decoration: none;
  }
}

.content {
  font-family: $font-family;

  &:hover,
  &:focus {
    background: $primary-dark;
  }

  &:focus {
    box-shadow: 0 0 1px 2px $primary-dark;
    border-color: $content-background;
    outline: 0;
  }
}

.plain {
  margin-top: 0;

  .content {
    cursor: default;
  }
}

.editable {
  margin: $gap 0 0 $gap;

  .content {
    .delete {
      color: $site-background;
      font-size: $font-size;
      margin-left: 4px;
    }

    &:hover,
    &:focus,
    &.is-focused {
      background: $offline-dark;
      color: $text-accent;

      .delete {
        color: $text-color;
      }
    }

    &:focus {
      box-shadow: 0 0 1px 1px $offline-dark;
    }
  }
}
